import React, {useEffect, useState, useMemo} from 'react';
import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import {Card} from 'reactstrap';
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  RSelect,
} from '../../components/Component';
import {useHistory} from 'react-router';
import {Modal, ModalBody, FormGroup} from 'reactstrap';
import DataTable from 'react-data-table-component';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
} from 'reactstrap';
import classnames from 'classnames';
import Status from './modalstatus';
import Swal from 'sweetalert2';
import GetDestinationTypes from '../helpers/getdestinationtypes';
import {useAuth0} from '@auth0/auth0-react';
import axios from 'axios';
import GetBg from '../helpers/getbg';
import Getbs from '../helpers/getbs';
import GetTipos from '../helpers/gettipos';
import GetPlantas from '../helpers/getplantas';
import GetTypes from '../helpers/gettypes';
import SelectProvider from '../../Exportation/followUp/Components/helpers/providerSelects';
import CustomSelect from '../../Exportation/followUp/Components/customselect';
import GetStatus from '../../plasticos/helpers/getstatus';
import CreateOrder from '../helpers/PostOrder';
import {BACKEND} from '../../consts/backend';

const Principal = () => {
  const {user} = useAuth0();
  const history = useHistory();
  const [activeIconTab, setActiveIconTab] = useState('5');
  const [roles, setRoles] = useState([]);

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  const [etas, setetas] = useState({
    shipment: '',
    eta: '',
  });

  const [atas, setatas] = useState({
    shipment: '',
    ata: '',
  });

  const [etds, setetds] = useState({
    shipment: '',
    etd: '',
  });

  const [atds, setatds] = useState({
    shipment: '',
    atd: '',
  });

  const [canales, setcanales] = useState({
    shipment: '',
    canal: '',
  });

  const [terminales, setterminales] = useState({
    terminal: '',
    canal: '',
  });

  const [incoterms, setIncoterms] = useState([]);
  const [pucharseType, setPucharseType] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [mot, setMot] = useState([]);
  const [countries, setCountries] = useState([]);
  const [plants, setPlants] = useState([]);
  const [port, setPort] = useState([]);
  const [vendors, setVensors] = useState([]);
  const [status, setStatus] = useState([]);
  const [carrier, setCarrier] = useState([]);

  const [containers, setcontainers] = useState();
  const [EditEtaModal, setEditEtaModal] = useState(false);
  const [EditContainerModal, setEditContainerModal] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalTab, setModalTab] = useState('1');
  const [update, setupdate] = useState(null);
  const [newOrder, setNewOrder] = useState({
    order_info: {
      status_id: null, //number,
      po: null, //string,
      business_group_id: null, //number,
      business_id: null, //number,
      shipper_id: null, //number,

      cnee: null, //number

      origin_country_id: null, //number,
      origin_plant_id: null, //number,

      destination_country_id: null, //number,
      destination_plant_id: null, //number,

      destination_port: null, //number,
      destination_type_id: null, //number,

      invoice_number: null, //string,
      invoice_amount: null, //number,

      transport_mode_id: null, //number,
      incoterm_id: null, //number,
      mbl: null, //number,
      hbl: null, //number,
      document_reception: null, //date,
      release_date: null, //Date,pendiente
      purchase_type_id: null, //number
    },
    materials: {
      ncm: null, //string,
      unit_price: null, //number,
      gross_weight: null, //number,
      description: null, //string,
      net_weight: null, //number,
      quantity: null, //number,
      equipment_type: null, //string,
    },
    shipment: {
      eta: null, //date,
      ata: null, //date,
      etd: null, //date,
      atd: null, //date,
      carrier_id: null, //number,
      terminal_id: null, //string,
    },
  });

  const bgs = [];
  const bs = [];
  //const vendors = [];
  const cnee = [];
  //const countries = [];
  const plantas = [];
  const modalito = [];
  //const incoterms = [];
  const destinationtypes = [];
  const purchasetype = [];
  const types = [];

  GetTypes().then((resp) =>
    resp.forEach((element) =>
      types.push({value: element[0], label: element[1]})
    )
  );

  GetBg().then((resp) =>
    resp.forEach((element) =>
      bgs.push({value: element[0], label: element[1]})
    )
  );
  Getbs().then((resp) =>
    resp.forEach((element) =>
      bs.push({value: element[0], label: element[1]})
    )
  );

  GetTipos().then((resp) =>
    resp.forEach((element) =>
      modalito.push({value: element[0], label: element[1]})
    )
  );

  const destination = [];
  GetDestinationTypes().then((resp) => {
    resp.forEach((element) =>
      destination.push({value: element[0], label: element[1]})
    );
  });

  GetPlantas().then((resp) =>
    resp.forEach((element) =>
      plantas.push({value: element[0], label: element[1]})
    )
  );

  const youCant = async () => {
    Swal.fire({
      title: "You can't perform this Action?",
      text: "You'r role doesn't have these permissions !",
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok!',
    });
  };

  const isReadOnly = useMemo(
    () =>
      !roles.some((role) =>
        ['maintainer', 'operator'].includes(role.toLowerCase())
      ),
    [roles]
  );

  const getRoles = () => {
    axios
      .get(`${BACKEND}/get-role/${user.sub}`)
      .then((res) => {
        if (res.data instanceof Array) {
          setRoles(res.data.map((role) => role.name));
        }
      })
      .catch((err) => console.warn(err));
  };

  const onChange = (e, id = '', index = '', name = '') => {
    if (id) {
      let auxName = newOrder[name];

      setNewOrder((element) => ({
        ...element,
        [name]: {
          ...auxName,
          [id]: e.value,
        },
      }));
    } else {
      let auxName = newOrder[e.target.name];
      setNewOrder((element) => ({
        ...element,
        [e.target.name]: {
          ...auxName,
          [e.target.id]: e.target.value,
        },
      }));
    }
  };

  const isEmpty = (promp) =>
    Object.values(newOrder[promp]).every((x) => x == null || x == '');

  const onSubmit = async () => {
    /* let aux = Object.keys(newOrder.order_info).every(
      (x) =>
        newOrder.order_info[x] !== null ||
        newOrder.order_info[x] !== ''
    );
    let aux1 = Object.keys(newOrder.materials).every(
      (x) =>
        newOrder.materials[x] !== null || newOrder.materials[x] !== ''
    );
    let aux2 = Object.keys(newOrder.shipment).every(
      (x) =>
        newOrder.shipment[x] !== null || newOrder.shipment[x] !== ''
    ); */
    /* if (!aux && !aux1 && !aux2) { */
    await CreateOrder(newOrder).then((response) => {
      if (response.state) {
        Swal.fire({
          title: 'Save',
          icon: 'success',
          text: 'New Order Created',
          showCancelButton: false,
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          title: 'Save',
          icon: 'error',
          text: 'Something went wrong',
          showCancelButton: false,
        });
      }
    });
    /* } else {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'Fill all the text boxes.',
        showCancelButton: true,
        showConfirmButton: false,
      });
    } */
  };

  const tipos = [
    {
      value: 'LCL',
      label: 'LCL',
    },
    {
      value: '40',
      label: '40',
    },
    {
      value: '20',
      label: '20',
    },
  ];

  const canal = [
    {
      value: 'Green',
      label: 'Green',
    },
    {
      value: 'Red',
      label: 'Red',
    },
    {
      value: 'Orange',
      label: 'Orange',
    },
    {
      value: 'No se informa',
      label: 'No se informa',
    },
  ];

  const terminal = [
    {
      value: 'EXOLGAN',
      label: 'EXOLGAN',
    },
    {
      value: 'TERMINAL SUR',
      label: 'TERMINAL SUR',
    },
    {
      value: 'MERCOCARGA SA',
      label: 'MERCOCARGA SA',
    },
    {
      value: 'GEMEZ SA BARRACAS',
      label: 'GEMEZ SA BARRACAS',
    },
    {
      value: 'TERMINAL 4',
      label: 'TERMINAL 4',
    },
    {
      value: 'BODEGA IMPO/EXPO EZE',
      label: 'BODEGA IMPO/EXPO EZE',
    },
    {
      value: 'ZARATE',
      label: 'ZARATE',
    },
    {
      value: 'TRP',
      label: 'TRP',
    },
    {
      value: 'DEPOSITO TAGSA',
      label: 'DEPOSITO TAGSA',
    },
    {
      value: 'TERMINAL 1 2 Y 3',
      label: 'TERMINAL 1 2 Y 3',
    },
    {
      value: 'MURCHISON',
      label: 'MURCHISON',
    },
    {
      value: 'PLAZOL CONTENEDORES',
      label: 'PLAZOL CONTENEDORES',
    },
    {
      value: 'ROSARIO',
      label: 'ROSARIO',
    },
    {
      value: 'DEPOSITO TERMINAL ZA',
      label: 'DEPOSITO TERMINAL ZA',
    },
    {
      value: 'DE FI ZARATE SA',
      label: 'DE FI ZARATE SA',
    },
    {
      value: ' Terminales Rio de la Plata',
      label: ' Terminales Rio de la Plata',
    },
    {
      value: 'DE FI ZARATE SA',
      label: 'DE FI ZARATE SA',
    },

    {
      value: 'TEFASA 1',
      label: 'TEFASA 1',
    },
    {
      value: 'TRANSPORTE PADILLA S',
      label: 'TRANSPORTE PADILLA S',
    },
    {
      value: 'EZEIZA',
      label: 'EZEIZA',
    },

    {
      value: 'BAHIA BLANCA',
      label: 'BAHIA BLANCA',
    },
    {
      value: 'BINDER 1',
      label: 'BINDER 1',
    },
    {
      value: 'SAN MARTIN',
      label: 'SAN MARTIN',
    },
    {
      value: 'Logexpor',
      label: 'Logexpor',
    },
    {
      value: 'Deposito Fiscal Mendoza',
      label: 'Deposito Fiscal Mendoza',
    },
    {
      value: ' SILCAR ROSARIO',
      label: ' SILCAR ROSARIO',
    },
  ];

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const roleUpdate = setInterval(() => {
      getRoles();
    }, 15000);
    return () => clearInterval(roleUpdate);
  });

  useEffect(() => {
    // incoterm
    new SelectProvider().getincoterms().then((response) => {
      let aux = response.map((element) => ({
        value: element.incoterm_id,
        label: element.incoterm_code,
      }));
      setIncoterms(aux);
    });

    // pucharsetype
    new SelectProvider().getPucharseType().then((response) => {
      setPucharseType(response);
    });

    // companies
    new SelectProvider().getCompanies().then((response) => {
      setCompanies(response);
    });

    new SelectProvider()
      .gettransportation_modes()
      .then((response) => {
        let aux = response.map((element) => ({
          value: element.transportation_mode_id,
          label: element.transportation_mode_name,
        }));
        setMot(aux);
      });

    new SelectProvider().getcountries().then((response) => {
      let aux = response.map((element) => ({
        value: element.id,
        label: element.name,
        code: element.iso,
      }));
      setCountries(aux);
    });

    new SelectProvider().getplants().then((response) => {
      let aux = response.map((element) => ({
        value: element.plant_id,
        label: element.plant_code,
      }));
      setPlants(aux);
    });

    new SelectProvider().getdowport().then((response) => {
      let aux = response.map((element) => ({
        value: element.id,
        label: `${element.dp_name} ${element.dp_code}`,
        code: element.country_iso,
      }));
      setPort(aux);
    });

    new SelectProvider().getVendors().then((response) => {
      setVensors(response);
    });

    new SelectProvider().getcarriers().then((response) => {
      let aux = response.map((element) => ({
        value: element.carrier_id,
        label: element.carrier_name,
      }));
      setCarrier(aux);
    });

    GetStatus().then((resp) => {
      let aux = resp.map((element) => ({
        value: element[0],
        label: element[1],
      }));
      setStatus(aux);
    });
  }, []);

  const [cont, setcont] = useState({
    cont: '',
    qty: '',
    tipo: '',
    id: '',
    mid: '',
    sid: '',
  });

  const ActionComponent = ({row, onClick}) => {
    const clickHandler = () => onClick(row);
    return (
      <Button onClick={clickHandler}>
        <Icon className="text-danger" name="trash" />
      </Button>
    );
  };

  const ActionComponent2 = ({id_m, id_s, id_o, id_c, onClick}) => {
    const clickHandler = () => onClick(id_m, id_s, id_o, id_c);
    return (
      <Button onClick={clickHandler}>
        <Icon className="text-ssbazul" name="files-fill" />
      </Button>
    );
  };

  const volver = () => {
    window.location.replace(`/listplasticos`);
  };

  return (
    <React.Fragment>
      <Head title="SSB SYSTEM"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Create New Order
                <strong className="text-primary small"></strong>
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex"
                onClick={volver}
              >
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
              <a
                href="#back"
                onClick={(ev) => {
                  ev.preventDefault();
                  history.goBack();
                }}
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <Icon name="arrow-left"></Icon>
              </a>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card text-ssbazul"></ul>

                <Content>
                  <Block className="p-1">
                    <BlockHead>
                      <BlockTitle
                        className="text-ssbnaranja"
                        tag="h5"
                      >
                        General Information &nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </BlockTitle>
                    </BlockHead>
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Status
                          </span>
                          <span className="profile-ud-value">
                            <CustomSelect
                              id={'status_id'}
                              name={'order_info'}
                              optionsSelect={status}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">PO</span>
                          <span className="profile-ud-value">
                            <input
                              type="number"
                              className="form-control text-center"
                              id={'po'}
                              name={'order_info'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            SSB Ref.
                          </span>
                          <span className="profile-ud-value">
                            <input
                              className="form-control text-center"
                              disabled
                            ></input>
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Business Group
                          </span>

                          <span className="profile-ud-value">
                            <FormGroup>
                              <CustomSelect
                                optionsSelect={bgs}
                                onChange={onChange}
                                name={'order_info'}
                                id={'business_group_id'}
                              />
                            </FormGroup>
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Business
                          </span>
                          <span className="profile-ud-value">
                            <FormGroup>
                              <CustomSelect
                                optionsSelect={bs}
                                onChange={onChange}
                                name={'order_info'}
                                id={'business_id'}
                              />
                            </FormGroup>
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Shipper
                          </span>
                          <span className="profile-ud-value">
                            <CustomSelect
                              optionsSelect={vendors}
                              name={'order_info'}
                              id={'shipper_id'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Cnee
                          </span>
                          <span className="profile-ud-value">
                            <CustomSelect
                              optionsSelect={companies}
                              name={'order_info'}
                              id={'cnee'}
                              onChange={onChange}
                            />
                            {/* {update ? update.cnee : null} */}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Origin Country
                          </span>
                          <span className="profile-ud-value">
                            <CustomSelect
                              optionsSelect={countries}
                              name={'order_info'}
                              id={'origin_country_id'}
                              onChange={onChange}
                            />
                            {/* {update ? update.origin : null} */}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Dest. Country
                          </span>
                          <span className="profile-ud-value">
                            {/* {update
                                  ? update.destinationcountry
                                  : null} */}
                            <CustomSelect
                              optionsSelect={countries}
                              name={'order_info'}
                              id={'destination_country_id'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Origin Plant
                          </span>
                          <span className="profile-ud-value">
                            {/* {update ? update.originplant : null} */}
                            <CustomSelect
                              optionsSelect={plantas}
                              name={'order_info'}
                              id={'origin_plant_id'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Destination Plant
                          </span>
                          <span className="profile-ud-value">
                            <CustomSelect
                              optionsSelect={plantas}
                              name={'order_info'}
                              id={'destination_plant_id'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Invoice Number
                          </span>
                          <span className="profile-ud-value">
                            <input
                              type="text"
                              className="form-control text-center"
                              name={'order_info'}
                              id={'invoice_number'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Invoice Value
                          </span>
                          <span className="profile-ud-value">
                            <input
                              type="number"
                              className="form-control text-center"
                              name={'order_info'}
                              id={'invoice_amount'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Block>
                  <div>
                    {' '}
                    <hr></hr>
                  </div>
                  <Block className="p-4">
                    <BlockHead className="nk-block-head-line">
                      <BlockTitle
                        tag="h6"
                        className="overline-title text-ssbnaranja"
                      >
                        Additional Information
                      </BlockTitle>
                    </BlockHead>
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            MBL Number
                          </span>
                          <span className="profile-ud-value">
                            <input
                              type="text"
                              className="form-control text-center"
                              name={'order_info'}
                              id={'mbl'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            HBL Number
                          </span>
                          <span className="profile-ud-value">
                            <input
                              type="text"
                              className="form-control text-center"
                              name={'order_info'}
                              id={'hbl'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Transport Mode
                          </span>
                          <span className="profile-ud-value">
                            <FormGroup>
                              <CustomSelect
                                optionsSelect={mot}
                                name={'order_info'}
                                id={'transport_mode_id'}
                                onChange={onChange}
                              />
                            </FormGroup>
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Destination Port
                          </span>
                          <span className="profile-ud-value">
                            <FormGroup>
                              <CustomSelect
                                optionsSelect={port}
                                name={'order_info'}
                                id={'destination_port'}
                                onChange={onChange}
                              />
                            </FormGroup>
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Incoterms
                          </span>
                          <span className="profile-ud-value">
                            {/* {update ? update.incoterms : null} */}
                            <CustomSelect
                              optionsSelect={incoterms}
                              name={'order_info'}
                              id={'incoterm_id'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Destination Type
                          </span>
                          {/*roles.some((role) =>
                            ['maintainer', 'operator'].includes(
                              role.toLowerCase()
                            )
                          ) ? (
                            <span className="profile-ud-value">
                              <CustomSelect
                                optionsSelect={destination}
                                name={'order_info'}
                                id={'destination_type_id'}
                                onChange={onChange}
                              />
                            </span>
                          ) : null*/}
                            <span className="profile-ud-value">
                              <CustomSelect
                                optionsSelect={destination}
                                name={'order_info'}
                                id={'destination_type_id'}
                                onChange={onChange}
                              />
                            </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Purchase Type
                          </span>
                          <span className="profile-ud-value">
                            <CustomSelect
                              optionsSelect={pucharseType}
                              name={'order_info'}
                              id={'purchase_type_id'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Equipment Type
                          </span>
                          <span className="profile-ud-value">
                            <CustomSelect
                              optionsSelect={types}
                              name={'materials'}
                              id={'equipment_type'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Reception Doc
                          </span>
                          <span className="profile-ud-value">
                            <input
                              className="form-control"
                              type="date"
                              name="order_info"
                              id="document_reception"
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Release Date
                          </span>
                          <input
                            className="form-control"
                            type="date"
                            id="release_date"
                            name="order_info"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </div>{' '}
                    <span>
                      <hr></hr>
                    </span>
                  </Block>
                  <Block className="p-4">
                    <BlockHead className="nk-block-head-line">
                      <BlockTitle
                        tag="h6"
                        className="overline-title text-ssbnaranja"
                      >
                        Materials
                      </BlockTitle>
                    </BlockHead>
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Material Number
                          </span>
                          <span className="profile-ud-value">
                            <input
                              type="text"
                              className="form-control text-center"
                              name={'materials'}
                              id={'ncm'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Description
                          </span>
                          <span className="profile-ud-value">
                            <FormGroup>
                              <input
                                type="text"
                                className="form-control text-center"
                                name={'materials'}
                                id={'description'}
                                onChange={onChange}
                              />
                            </FormGroup>
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Unit Price
                          </span>
                          <span className="profile-ud-value">
                            <FormGroup>
                              <input
                                type="number"
                                className="form-control text-center"
                                name="materials"
                                id="unit_price"
                                onChange={onChange}
                              />
                            </FormGroup>
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Net Weight
                          </span>
                          <span className="profile-ud-value">
                            <FormGroup>
                              <input
                                type="number"
                                id="net_weight"
                                className="form-control text-center"
                                name="materials"
                                onChange={onChange}
                              />
                            </FormGroup>
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Gross Weight
                          </span>
                          <span className="profile-ud-value">
                            <input
                              type="text"
                              id="gross_weight"
                              className="form-control text-center"
                              name="materials"
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Quantities
                          </span>
                          <input
                            type="number"
                            className="form-control text-center"
                            id={'quantity'}
                            name={'materials'}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </div>{' '}
                    <span>
                      <hr></hr>
                    </span>
                  </Block>
                  <Block className="p-4">
                    <BlockHead className="nk-block-head-line">
                      <BlockTitle
                        tag="h6"
                        className="overline-title text-ssbnaranja"
                      >
                        Shipment
                      </BlockTitle>
                    </BlockHead>
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Vessel/Carrier
                          </span>
                          <span className="profile-ud-value">
                            <CustomSelect
                              optionsSelect={carrier}
                              id={'carrier_id'}
                              name={'shipment'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            ETD DATE
                          </span>
                          <span className="profile-ud-value">
                            <FormGroup>
                              <input
                                className="form-control"
                                type="date"
                                id="etd"
                                name="shipment"
                                onChange={onChange}
                              />
                            </FormGroup>
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            ATD DATE
                          </span>
                          <span className="profile-ud-value">
                            <FormGroup>
                              <input
                                className="form-control"
                                type="date"
                                id="atd"
                                name="shipment"
                                onChange={onChange}
                              />
                            </FormGroup>
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            ETA DATE
                          </span>
                          <span className="profile-ud-value">
                            <FormGroup>
                              <input
                                className="form-control"
                                type="date"
                                id="eta"
                                name="shipment"
                                onChange={onChange}
                              />
                            </FormGroup>
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            ATA DATE
                          </span>
                          <span className="profile-ud-value">
                            <input
                              className="form-control"
                              type="date"
                              id="ata"
                              name="shipment"
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Terminals
                          </span>
                          <span className="profile-ud-value">
                            <CustomSelect
                              optionsSelect={terminal}
                              id={'terminal_id'}
                              name={'shipment'}
                              onChange={onChange}
                            />
                          </span>
                        </div>
                      </div>
                    </div>{' '}
                    <span>
                      <hr></hr>
                      <Button
                        color="primary"
                        onClick={() => setEditContainerModal(true)}
                      >
                        Create New Order
                      </Button>
                    </span>
                  </Block>
                  <Content>
                    <Row className="text-center"></Row>
                  </Content>
                </Content>
              </div>
            </div>
          </Card>
        </Block>
      </Content>

      <Modal
        isOpen={EditContainerModal}
        toggle={() => setEditContainerModal(false)}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setEditContainerModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm" />
          </a>
          <div className="p-2">
            <h5 className="title text-primary">New Order</h5>
            <div className="mt-4 mb-4">
              <pre>{JSON.stringify(newOrder, null, 2) ?? ''}</pre>
            </div>
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
              <li>
                {/*roles.some((role) =>
                  ['maintainer', 'operator'].includes(
                    role.toLowerCase()
                  )
                ) ? (
                  <Button
                    color="primary"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setEditContainerModal(false);
                      onSubmit();
                    }}
                  >
                    Submit
                  </Button>
                ) : undefined*/}
                  <Button
                    color="primary"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setEditContainerModal(false);
                      onSubmit();
                    }}
                  >
                    Submit
                  </Button>
              </li>
            </ul>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Principal;
